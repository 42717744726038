<template>
	<form @submit.prevent="handleSubmit">
		<div class="row">
			<div v-if="vehicle.account" class="form-group col-sm-12">
				<label for="parent_id">Account:</label>
				<input
					class="form-control"
					disabled
					name="account_id"
					type="text"
					v-model="vehicle.account.account_name"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6 required">
				<label for="vehicle_no">Vehicle No:</label>
				<input
					class="form-control"
					required=""
					name="vehicle_no"
					type="text"
					v-model="vehicle.vehicle_no"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6 required">
				<label for="vehicle_code">Vehicle Code:</label>
				<input
					class="form-control"
					required=""
					name="vehicle_code"
					type="text"
					v-model="vehicle.vehicle_code"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="total_seat">Total Seat:</label>
				<input
					class="form-control"
					name="total_seat"
					type="number"
					v-model.number="vehicle.total_seat"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="machine_no">Machine Number:</label>
				<input
					class="form-control"
					name="machine_no"
					type="text"
					v-model="vehicle.machine_no"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="chassis_no">Chassis Number:</label>
				<input
					class="form-control"
					name="chassis_no"
					type="text"
					v-model="vehicle.chassis_no"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="capacity">Capacity (cc):</label>
				<input
					class="form-control"
					name="capacity"
					type="number"
					v-model.number="vehicle.capacity"
					min="0"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6 required">
				<label for="type">Type:</label>
				<select
					class="form-control"
					name="type"
					required=""
					v-model="vehicle.type"
				>
					<option
						v-for="typeV in vehicleOptions"
						:key="typeV.value"
						:value="typeV.value"
						style="color:black;"
					>
						{{ typeV.label }}
					</option>
				</select>
			</div>
			<div class="form-group col-sm-12 col-md-6 required">
				<label for="odometer">Odometer:</label>
				<input
					class="form-control"
					name="odometer"
					required=""
					type="number"
					v-model.number="vehicle.odometer"
					step="any"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-4">
				<label for="color">Color:</label>
				<input
					class="form-control"
					name="color"
					type="text"
					v-model="vehicle.color"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-4">
				<label for="manufacture">Manufacturer:</label>
				<input
					class="form-control"
					name="manufacture"
					type="text"
					v-model="vehicle.manufacture"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-4">
				<label for="manufacture_year">Manufacture Year:</label>
				<input
					class="form-control"
					name="manufacture_year"
					type="number"
					v-model="vehicle.manufacture_year"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="stnk_number">STNK Number:</label>
				<input
					class="form-control"
					name="stnk_number"
					type="text"
					v-model="vehicle.stnk_number"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="stnk_date">STNK Date:</label>
				<input
					class="form-control"
					name="stnk_date"
					type="date"
					v-model="stnk_date"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="keur_number">KEUR Number:</label>
				<input
					class="form-control"
					name="keur_number"
					type="text"
					v-model="vehicle.keur_number"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="keur_date">KEUR Date:</label>
				<input
					class="form-control"
					name="keur_date"
					type="date"
					v-model="keur_date"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="kp_number">KP Number:</label>
				<input
					class="form-control"
					name="kp_number"
					type="text"
					v-model="vehicle.kp_number"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="kp_date">KP Date:</label>
				<input
					class="form-control"
					name="kp_date"
					type="date"
					v-model="kp_date"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="sipa_number">SIPA Number:</label>
				<input
					class="form-control"
					name="sipa_number"
					type="text"
					v-model="vehicle.sipa_number"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="sipa_date">SIPA Date:</label>
				<input
					class="form-control"
					name="sipa_date"
					type="date"
					v-model="sipa_date"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="ibm_number">IBM Number:</label>
				<input
					class="form-control"
					name="ibm_number"
					type="text"
					v-model="vehicle.ibm_number"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="ibm_date">IBM Date:</label>
				<input
					class="form-control"
					name="ibm_date"
					type="date"
					v-model="ibm_date"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="other_number">Other Number:</label>
				<input
					class="form-control"
					name="other_number"
					type="text"
					v-model="vehicle.other_number"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="other_number_date">Other Number Date:</label>
				<input
					class="form-control"
					name="other_number_date"
					type="date"
					v-model="other_number_date"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6 required">
				<label for="status">Status:</label>
				<select
					class="form-control"
					name="status"
					v-model.number="vehicle.status"
				>
					<option value="1" style="color:black;">Active</option>
					<option value="2" style="color:black;">Inactive</option>
				</select>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="parent_id">GPS:</label>
				<Select2
					name="gps_id"
					v-model.number="vehicle.gps_id"
					:options="gpsOptions"
				/>
			</div>
			<InputImage label="Picture" v-on:selected-image="setImage" />
			<div v-if="vehicle.pic_url" class="form-group col-sm-12">
				<img :src="vehicle.pic_url" height="200" />
			</div>
			<div class="form-group col-sm-12">
				<input class="btn btn-primary" type="submit" value="Save" />
				<router-link class="btn btn-default ml-1" to="/vehicles"
					>Cancel</router-link
				>
			</div>
		</div>
		<div
			v-if="this.onRequest"
			class="modal-backdrop fade show"
			style="display:flex; align-items: center; justify-content: center;"
		>
			<img :src="require('../assets/loading.gif')" />
		</div>
	</form>
</template>

<script>
	import { mapState, mapActions } from "vuex";
	import moment from "moment";
	import InputImage from "../components/InputImage.vue";
	import Select2 from "vue3-select2-component";
	import { optionVehicle } from "../_helpers/VehicleIcon";
	export default {
		computed: {
			...mapState("vehicles", ["vehicle", "onRequest"]),
			...mapState("gps", ["gpss"]),
			stnk_date: {
				get() {
					if (this.vehicle && this.vehicle.stnk_date) {
						return moment(this.vehicle.stnk_date).format("YYYY-MM-DD");
					}
					return null;
				},
				set(val) {
					this.vehicle.stnk_date = val;
				},
			},
			keur_date: {
				get() {
					if (this.vehicle && this.vehicle.keur_date) {
						return moment(this.vehicle.keur_date).format("YYYY-MM-DD");
					}
					return null;
				},
				set(val) {
					this.vehicle.keur_date = val;
				},
			},
			kp_date: {
				get() {
					if (this.vehicle && this.vehicle.kp_date) {
						return moment(this.vehicle.kp_date).format("YYYY-MM-DD");
					}
					return null;
				},
				set(val) {
					this.vehicle.kp_date = val;
				},
			},
			sipa_date: {
				get() {
					if (this.vehicle && this.vehicle.sipa_date) {
						return moment(this.vehicle.sipa_date).format("YYYY-MM-DD");
					}
					return null;
				},
				set(val) {
					this.vehicle.sipa_date = val;
				},
			},
			ibm_date: {
				get() {
					if (this.vehicle && this.vehicle.ibm_date) {
						return moment(this.vehicle.ibm_date).format("YYYY-MM-DD");
					}
					return null;
				},
				set(val) {
					this.vehicle.ibm_date = val;
				},
			},
			other_number_date: {
				get() {
					if (this.vehicle && this.vehicle.other_number_date) {
						return moment(this.vehicle.other_number_date).format("YYYY-MM-DD");
					}
					return null;
				},
				set(val) {
					this.vehicle.other_number_date = val;
				},
			},
		},
		data() {
			return {
				file_image: null,
				gpsOptions: [],
				vehicleOptions: optionVehicle,
			};
		},
		components: {
			InputImage,
			Select2,
		},
		methods: {
			handleSubmit() {
				if (this.onRequest) return;
				this.vehicle.stnk_date = this.stnk_date;
				this.vehicle.keur_date = this.keur_date;
				this.vehicle.kp_date = this.kp_date;
				this.vehicle.sipa_date = this.sipa_date;
				this.vehicle.ibm_date = this.ibm_date;
				this.vehicle.other_number_date = this.other_number_date;
				this.vehicle.file_image = this.file_image;
				this.edit(this.vehicle);
			},
			...mapActions("vehicles", ["edit", "get"]),
			...mapActions("gps", { getGps: "get_gps_by_account" }),
			setImage(image) {
				this.file_image = image;
			},
		},
		created() {
			this.$emit("onChildInit", "Edit Vehicle");
			this.get(this.$route.params.id);
		},
		watch: {
			gpss(nextState, prevState) {
				if (nextState !== prevState) {
					this.gpsOptions = [];
					if (nextState.length > 0) {
						nextState.map((item) => {
							this.gpsOptions.push({
								id: item.id,
								text: item.imei + " - " + item.serial_number,
							});
						});
					}
					return;
				}
			},
			vehicle(nextState, prevState) {
				if (nextState !== prevState) {
					this.getGps({ account_id: nextState.account_id });
					return;
				}
			},
		},
	};
</script>
